<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mgrupajeequipamiento-modals
			:modelName="modelName"
		></mgrupajeequipamiento-modals>
	</div>
</template>

<script>
import mgrupajeequipamientoActions from './MgrupajeequipamientoActions';
import mgrupajeequipamientoModals from './MgrupajeequipamientoModals.vue';

export default {
	name: 'mgrupajeequipamiento-grid',
	components: {
		'mgrupajeequipamiento-modals': mgrupajeequipamientoModals
	},
	data() {
		return {
			modelName: 'mgrupajeequipamiento',
			actions: mgrupajeequipamientoActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
